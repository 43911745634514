export function useCase() {
  /**
   * This will convert all object value to camelCase,
   * Since our Laravel API return snake_case by default,
   * we need to convert it to camelCase to follow our TypeScript interface naming convention.
   */
  function toCamelCase(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map((v: any) => toCamelCase(v))
    }
    else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [key.replace(/([-_][a-z])/gi, $1 => $1.toUpperCase().replace('_', ''))]: toCamelCase(obj[key]),
        }),
        {},
      )
    }
    return obj
  }

  return {
    toCamelCase,
  }
}
